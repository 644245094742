@media (min-width: 480px) {
  .features-container {
    max-width: calc(430px * 5);
  }
  .features-header {
    max-width: 430px;
  }
  .feature-content {
    max-width: 430px;
  }
}
.features-container {
  margin-top: 32px;
  width: 500vw;
  height: 100dvh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
}

.features-header {
  display: flex;
  margin-top: 16px;
  gap: 11px;
  position: absolute;
  justify-content: center;
  z-index: 1;
  width: 100vw;
  &-mob {
    @extend .features-header;
  }
}

.feature-content {
  width: 100vw;
  height: 100dvh;
  background-color: #1A1A1A;
  border-radius: 10px;
  overflow-y: hidden;
}

.feature-icon-container {
  width: 60px;
  height: 52px;
  position: relative;
  &-mob {
    @extend .feature-icon-container;
  }
}

.feature-icon {
  width: 60px;
  height: 52px;
  position: absolute;
  &-mob {
    @extend .feature-icon;
  }
}

.feature-title {
  @include sf-bold;
  font-size: 32px;
  font-weight: 700;
  color: #FFFFFF;
  text-align: center;
  padding: 0px 19px 0px 19px;
  &-mob {
    @extend .feature-title;
  }
}

.feature-subtitle {
  @include sf-regular;
  font-size: 14px;
  font-weight: 400;
  color: #BAC4C4;
  text-align: center;
  padding: 12px 19px 0px 19px;
  &-mob {
    @extend .feature-subtitle;
  }
}

.feature-background {
  width: 100%;
  &-mob {
    @extend .feature-background;
  }
}

.feature-start-to-free-btn {
  @extend .second-btn;
  margin-left: 16px;
  margin-right: 16px;
  &:hover {
    cursor: pointer;
    color: #BAC4C4;
    opacity: 1;
  }
}
.feature-start-to-free-btn-container {
  position: absolute;
  bottom: 24px;
  width: 100vw;
}
