.review-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
  width: 100%;
  &-mob {
    @extend .review-container;
  }
}

.review-dots-container {
  padding-top: 5px;
  display: flex;
  gap: 5px;
}

.review-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: white;
}

.slick-slide div {
  outline: none;
}
