.page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.main-background-container {
  @extend .background-container;
  background: url(../imgs/background_main.webp) no-repeat;
  width: 100%;
  &-mob{
    @extend .main-background-container;
  }
}

.main-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  &-mob {
    @extend .main-page-container;
  }
}

.main-screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &-mob {
    @extend .main-screen-container;
  }
}

.main-subtitle {
  @include sf-medium;
  @extend .fltr-general-text-gradient;
  font-size: 20px;
  line-height: normal;
  &-mob {
    @extend .main-subtitle;
  }
}

.main-before-after {
  display: grid;
  place-items: center;
  position: relative;
  margin-bottom: -2px;
  margin-top: 16px;
}

.main-before-after-container {
  display: grid;
  place-items: center;
  height: 315px;
  top: 4px;
  --position: 30%;
  position: relative;
  overflow: hidden;
  &-mob {
    @extend .main-before-after-container;
  }
}

.main-before-after-subcontainer {
  aspect-ratio: 1/1;
  max-width: 800px;
  img {
    display: block;
    max-width: 274px;
    height: 348px;
  }
  &-mob {
    @extend .main-before-after-subcontainer;
  }
}

.slider-line {
  position: absolute;
  width: 1px;
  height: 344px;
  background: white;
  top: 4px;
  left: var(--position);
  transform: translateX(-50%);
  pointer-events: none;
}

.slider-button {
  position: absolute;
  background: url(../imgs/slider-button.png) no-repeat;
  background-size: cover;
  background-position: center;
  width: 39px;
  height: 20px;
  display: grid;
  place-items: center;
  top: 50%;
  left: var(--position);
  transform: translate(-50%, -50%);
  pointer-events: none;
  /* z-index: 100; */
}

.slider-image {
  width: 100%;
  height: 343px;
  object-fit: cover;
  object-position: left;
}

.image-before {
  position: absolute;
  height: 343px;
  width: var(--position);
}

.iphone-img {
  position: absolute;
  z-index: 3;
  max-width: 302px;
  width: 302px;
  height: 323px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &-mob {
    @extend .iphone-img;
  }
}

.main-line {
  margin-top: 6px;
  min-height: 1px;
  width: 90%;
  opacity: 0.3;
  background: rgba(255, 255, 255, 0.50);
  background-color: #FFFFFF;
}

.try-screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &-mob {
    @extend .try-screen-container;
  }
}

.try-app-img {
  width: 100%;
  &-mob{
    @extend .try-app-img;
  }
}

.video-section {
  @include sf-regular;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  color: #BAC4C4;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  video {
    width: 100%;
  }
}

//Questions

.questions-screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 32px;
  &-mob {
    @extend .questions-screen-container;
  }
}

.questions-container {
  display: flex;
  flex-direction: column;
  border-radius: 21px;
  background: #1D1F1F;
  margin-top: 24px;
  border-bottom: 1px solid #303333;
  border-top: 1px solid #303333;
  &-mob {
    @extend .questions-container;
  }
}

.question-container {
  display: flex;
  padding: 18px;
  flex-direction: column;
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.05) inset;
}

.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question-arrow {
  @include transition;
  content: url(../imgs/question-arrow.png);
  background-size: contain;
  background-position: right;
  width: 44px;
  height: 44px;
  margin-left: 19px;
  transform: rotate(180deg);
  &-active {
    @extend .question-arrow;
    transform: rotate(360deg)
  }
}

.question-title {
  @include transition;
  @include sf-bold;
  color: #BAC4C4;
  font-size: 18px;
  text-align: left;
  &-active {
    @extend .question-title;
    color: white;
  }
}

.question-answer {
  @include sf-regular;
  @include transition;
  color: #BAC4C4;
  font-size: 16px;
  line-height: 24px;
  height: 0px;
  overflow: hidden;
  &-active {
    @extend .question-answer;
    margin-top: 16px;
    height: max-content;
  }
}

// Footer

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 36px;
  &-mob {
    @extend .footer-container;
  }
}

.terms-policy-footer {
  display: flex;
  gap: 6px;
  align-items: center;
  margin-top: auto;
  .point {
    width: 2px;
    height: 2px;
    background-color: #BAC4C4;
  }
  a {
    @include sf-regular;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #BAC4C4;
  }
}

.footer-copyright {
  @include sf-regular;
  color: #BAC4C4;
  font-size: 14px;
  padding-top: 8px;
}
