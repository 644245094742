::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}

a {
  text-decoration: none;
}

html {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  margin: 0;
  background-color: black;
  overflow-y: visible;
  overflow-x: hidden;
  position: relative;
}

@media (min-width: 480px) {
  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    background-color: black;
    max-width: 430px;
    margin: auto;
    overflow-x: hidden;
  }
  .cookies-container, .cookies-container-mob {
    max-width: 430px;
  }
}
.root {
  height: 100%;
}

.app-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  overflow-x: hidden;
  position: relative;
}

.body-container {
  position: relative;
}

.fltr-general-text-gradient, .quiz-main-subtitle, .quiz-main-subtitle-mob, .main-subtitle, .main-subtitle-mob, .empty-gradient-btn {
  background: linear-gradient(85.81deg, #21FFB0 33.39%, #21E9EB 64.73%, #21E2FF 75.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.general-input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  height: 48px;
  border: 1px solid #505050;
  border-radius: 50px;
  background-color: transparent;
  color: white;
  font-size: 16px;
  line-height: 24px;
  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;
}
.general-input:focus {
  border-color: #FFFFFF;
  outline: none;
}
.general-input::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 16px;
  line-height: 24px;
  color: #303333;
}

.general-btn, .empty-gradient-btn, .general-btn-mob {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-size: 18px;
  line-height: 21px;
  color: black;
  max-width: 400px;
  width: 100%;
  min-height: 56px;
  transition: background-image 0.5s linear;
  background-image: linear-gradient(85.81deg, #21FFB0 33.39%, #21E9EB 64.73%, #21E2FF 75.17%);
  position: relative;
}
.general-btn:hover, .empty-gradient-btn:hover, .general-btn-mob:hover {
  cursor: pointer;
  opacity: 0.7;
}
.general-btn:focus, .empty-gradient-btn:focus, .general-btn-mob:focus {
  outline: none;
}
.general-btn:disabled, .empty-gradient-btn:disabled, .general-btn-mob:disabled {
  opacity: 0.5;
}
.general-btn-mob {
  height: 56px;
  width: 100%;
}
.general-btn-mob:hover {
  cursor: pointer;
  opacity: 1;
}
@keyframes changePosition {
  0% {
    right: 130px;
  }
  50% {
    right: 100px;
  }
  100% {
    right: 130px;
  }
}

.empty-gradient-btn {
  font-size: 17px;
  font-style: normal;
  line-height: 24px;
  text-transform: capitalize;
  min-height: 20px;
}
.empty-gradient-btn .arrow-btn {
  background: url(../imgs/arrow-right.svg) no-repeat;
  background-size: cover;
  position: absolute;
  right: 60px;
  width: 20px;
  height: 17px;
  animation-duration: 1.5s;
  animation-name: changePosition;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

.second-btn, .feature-start-to-free-btn, .second-btn-mob {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-size: 18px;
  line-height: 21px;
  color: black;
  max-width: 400px;
  height: 56px;
  transition: background 0.5s linear;
  background: #FFFFFF;
}
.second-btn:hover, .feature-start-to-free-btn:hover, .second-btn-mob:hover {
  cursor: pointer;
  opacity: 0.7;
}
.second-btn:focus, .feature-start-to-free-btn:focus, .second-btn-mob:focus {
  outline: none;
}
.second-btn:disabled, .feature-start-to-free-btn:disabled, .second-btn-mob:disabled {
  opacity: 0.5;
}
.second-btn-mob {
  height: 56px;
  width: 100%;
}

.title-text, .title-text-mob {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: white;
  font-size: 32px;
  line-height: 38px;
}
.title-text-mob {
  font-size: 32px;
  line-height: 38px;
}

.title-text, .title-text-mob {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: white;
  font-size: 30px;
  line-height: normal;
}
.title, .title-mob {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: white;
  font-size: 30px;
  line-height: normal;
  text-align: center;
}
.second-text, .second-text-mob {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: #BAC4C4;
}
.second-text-mob {
  font-size: 16px;
  line-height: 24px;
}

.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fltr-logo, .fltr-logo-mob {
  width: 85px;
  height: 28px;
}
.try-now, .try-now-mob {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  width: 90px;
  height: 31px;
}
.try-now-mob {
  width: 90px;
  height: 28px;
}

.background-container, .paywall-offer-after-container, .paywall-offer-after-container-mob, .paywall-offer-before-container, .paywall-offer-before-container-mob, .quiz-finish-background-container, .quiz-finish-background-container-mob, .quiz-start-background-container, .quiz-start-background-container-mob, .quiz-main-background-container, .quiz-main-background-container-mob, .main-background-container, .main-background-container-mob {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: contain !important;
}

.arrow, .back::before {
  border: solid #BAC4C4;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  width: 10px;
  height: 10px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left, .back::before {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.back {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  position: absolute;
  font-size: 16px;
  line-height: 24px;
  left: 25px;
  top: 25px;
  color: #BAC4C4;
}
.back::before {
  margin-right: 8px;
  content: "";
}

.progress-bar-container {
  position: relative;
  height: 16px;
}

.progress-bar-back {
  position: absolute;
  width: 100%;
  height: 16px;
  background: linear-gradient(270deg, rgba(73, 244, 255, 0.175) -0.42%, rgba(255, 255, 255, 0) 100%);
  border-radius: 20px;
  z-index: 1;
}

.progress-bar-front {
  position: absolute;
  height: 16px;
  background: linear-gradient(85.81deg, #21FFB0 33.39%, #21E9EB 64.73%, #21E2FF 75.17%);
  border-radius: 20px;
  z-index: 2;
  width: 0%;
  transition: width 10s linear;
}
.progress-bar-front:after {
  content: "";
  position: absolute;
  top: -2px;
  right: -10px;
  background: #1E2424;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 2px solid #FFFFFF;
}

.store-image, .google-store-image, .apple-store-image {
  width: 165px;
  height: 50px;
}

.apple-store-image {
  background: url(../imgs/apple-store.png) no-repeat;
  background-size: cover;
}

.google-store-image {
  background: url(../imgs/google-store.png) no-repeat;
  background-size: cover;
}

.cookies-container, .cookies-container-mob {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 12px;
  line-height: 14px;
  display: flex;
  position: fixed;
  top: calc(100vh - 36px);
  padding: 4px 16px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #BAC4C4;
  align-items: center;
  gap: 8px;
  z-index: 100;
}
.cookies-container a, .cookies-container-mob a {
  padding-left: 2px;
  color: #BAC4C4;
  text-decoration: underline;
}
.cookies-container .allow-btn, .cookies-container-mob .allow-btn {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: black;
  font-size: 12px;
  border-radius: 40px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  padding: 0px 24px;
}
.cookies-container .allow-btn:hover, .cookies-container-mob .allow-btn:hover {
  cursor: pointer;
}
.header-label, .header-label-mob {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: white;
  font-size: 16px;
  line-height: 24px;
}
.img-comp-slider {
  position: absolute;
  z-index: 6;
  cursor: ew-resize;
  /*set the appearance of the slider:*/
  width: 40px;
  height: 40px;
  background-color: #2196F3;
  opacity: 0.7;
  border-radius: 50%;
}

.privacy-terms-container, .privacy-terms-container-mob {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 18px;
  padding: 16px;
  text-align: center;
  overflow-y: auto;
  color: white;
}
.burger-button {
  transition-duration: 0.5s;
  position: relative;
  width: 24px;
  height: 24px;
}
.burger-button .icon {
  transition-duration: 0.5s;
  position: absolute;
  height: 0px;
  width: 15px;
  top: 11px;
  background-color: white;
}
.burger-button .icon:before {
  transition-duration: 0.5s;
  position: absolute;
  width: 21px;
  height: 2px;
  background-color: white;
  content: "";
  top: -4px;
  border-radius: 5px;
}
.burger-button .icon:after {
  transition-duration: 0.5s;
  position: absolute;
  width: 21px;
  height: 2px;
  background-color: white;
  content: "";
  top: 4px;
  border-radius: 5px;
}
.burger-button.open .icon {
  transition: 0.5s;
}
.burger-button.open .icon:before {
  transform: rotateZ(45deg) scaleX(1.25) translate(2px, 2px);
  width: 17px;
}
.burger-button.open .icon:after {
  transform: rotateZ(-45deg) scaleX(1.25) translate(3px, -3px);
  width: 17px;
}
.burger-button:hover {
  cursor: pointer;
}

.header, .header-mob {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  z-index: 1;
  overflow: hidden;
  -webkit-transition: all 0.5s ease-out, background 1s ease-out;
  transition: all 0.5s ease-out, background 1s ease-out;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  z-index: 100;
}
.menu, .menu-opened {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  height: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: black;
  gap: 131px;
  justify-content: center;
}
.menu-opened {
  height: 100svh;
}

.menu-item a {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: #BAC4C4;
  font-size: 20px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
}

.page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.main-background-container, .main-background-container-mob {
  background: url(../imgs/background_main.webp) no-repeat;
  width: 100%;
}
.main-page-container, .main-page-container-mob {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
}
.main-screen-container, .main-screen-container-mob {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.main-subtitle, .main-subtitle-mob {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 20px;
  line-height: normal;
}
.main-before-after {
  display: grid;
  place-items: center;
  position: relative;
  margin-bottom: -2px;
  margin-top: 16px;
}

.main-before-after-container, .main-before-after-container-mob {
  display: grid;
  place-items: center;
  height: 315px;
  top: 4px;
  --position: 30%;
  position: relative;
  overflow: hidden;
}
.main-before-after-subcontainer, .main-before-after-subcontainer-mob {
  aspect-ratio: 1/1;
  max-width: 800px;
}
.main-before-after-subcontainer img, .main-before-after-subcontainer-mob img {
  display: block;
  max-width: 274px;
  height: 348px;
}
.slider-line {
  position: absolute;
  width: 1px;
  height: 344px;
  background: white;
  top: 4px;
  left: var(--position);
  transform: translateX(-50%);
  pointer-events: none;
}

.slider-button {
  position: absolute;
  background: url(../imgs/slider-button.png) no-repeat;
  background-size: cover;
  background-position: center;
  width: 39px;
  height: 20px;
  display: grid;
  place-items: center;
  top: 50%;
  left: var(--position);
  transform: translate(-50%, -50%);
  pointer-events: none;
  /* z-index: 100; */
}

.slider-image {
  width: 100%;
  height: 343px;
  object-fit: cover;
  object-position: left;
}

.image-before {
  position: absolute;
  height: 343px;
  width: var(--position);
}

.iphone-img, .iphone-img-mob {
  position: absolute;
  z-index: 3;
  max-width: 302px;
  width: 302px;
  height: 323px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.main-line {
  margin-top: 6px;
  min-height: 1px;
  width: 90%;
  opacity: 0.3;
  background: rgba(255, 255, 255, 0.5);
  background-color: #FFFFFF;
}

.try-screen-container, .try-screen-container-mob {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.try-app-img, .try-app-img-mob {
  width: 100%;
}
.video-section {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  color: #BAC4C4;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
}
.video-section video {
  width: 100%;
}

.questions-screen-container, .questions-screen-container-mob {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 32px;
}
.questions-container, .questions-container-mob {
  display: flex;
  flex-direction: column;
  border-radius: 21px;
  background: #1D1F1F;
  margin-top: 24px;
  border-bottom: 1px solid #303333;
  border-top: 1px solid #303333;
}
.question-container {
  display: flex;
  padding: 18px;
  flex-direction: column;
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.05) inset;
}

.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question-arrow, .question-arrow-active {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  content: url(../imgs/question-arrow.png);
  background-size: contain;
  background-position: right;
  width: 44px;
  height: 44px;
  margin-left: 19px;
  transform: rotate(180deg);
}
.question-arrow-active {
  transform: rotate(360deg);
}

.question-title, .question-title-active {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: #BAC4C4;
  font-size: 18px;
  text-align: left;
}
.question-title-active {
  color: white;
}

.question-answer, .question-answer-active {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  color: #BAC4C4;
  font-size: 16px;
  line-height: 24px;
  height: 0px;
  overflow: hidden;
}
.question-answer-active {
  margin-top: 16px;
  height: max-content;
}

.footer-container, .footer-container-mob {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 36px;
}
.terms-policy-footer {
  display: flex;
  gap: 6px;
  align-items: center;
  margin-top: auto;
}
.terms-policy-footer .point {
  width: 2px;
  height: 2px;
  background-color: #BAC4C4;
}
.terms-policy-footer a {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #BAC4C4;
}

.footer-copyright {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: #BAC4C4;
  font-size: 14px;
  padding-top: 8px;
}

@media (min-width: 480px) {
  .features-container {
    max-width: 2150px;
  }
  .features-header, .features-header-mob {
    max-width: 430px;
  }
  .feature-content {
    max-width: 430px;
  }
}
.features-container {
  margin-top: 32px;
  width: 500vw;
  height: 100dvh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
}

.features-header, .features-header-mob {
  display: flex;
  margin-top: 16px;
  gap: 11px;
  position: absolute;
  justify-content: center;
  z-index: 1;
  width: 100vw;
}
.feature-content {
  width: 100vw;
  height: 100dvh;
  background-color: #1A1A1A;
  border-radius: 10px;
  overflow-y: hidden;
}

.feature-icon-container, .feature-icon-container-mob {
  width: 60px;
  height: 52px;
  position: relative;
}
.feature-icon, .feature-icon-mob {
  width: 60px;
  height: 52px;
  position: absolute;
}
.feature-title, .feature-title-mob {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 32px;
  font-weight: 700;
  color: #FFFFFF;
  text-align: center;
  padding: 0px 19px 0px 19px;
}
.feature-subtitle, .feature-subtitle-mob {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 400;
  color: #BAC4C4;
  text-align: center;
  padding: 12px 19px 0px 19px;
}
.feature-background, .feature-background-mob {
  width: 100%;
}
.feature-start-to-free-btn {
  margin-left: 16px;
  margin-right: 16px;
}
.feature-start-to-free-btn:hover {
  cursor: pointer;
  color: #BAC4C4;
  opacity: 1;
}

.feature-start-to-free-btn-container {
  position: absolute;
  bottom: 24px;
  width: 100vw;
}

.review-container, .review-container-mob {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
  width: 100%;
}
.review-dots-container {
  padding-top: 5px;
  display: flex;
  gap: 5px;
}

.review-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: white;
}

.slick-slide div {
  outline: none;
}

.quiz-page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.quiz-main-background-container, .quiz-main-background-container-mob {
  background: url(../imgs/background_main-quiz.webp) no-repeat;
  background-size: cover !important;
}
.quiz-start-background-container, .quiz-start-background-container-mob {
  background: url(../imgs/background_start-quiz.webp) no-repeat;
}
.quiz-finish-background-container, .quiz-finish-background-container-mob {
  background: url(../imgs/background_finish-quiz.webp) no-repeat;
}
.quiz-flow-container, .quiz-main-page-container, .quiz-main-page-container-mob, .quiz-start-page-container, .quiz-start-page-container-mob, .quiz-flow-container-mob {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  margin: 64px 16px 0px 16px;
}
.quiz-start-page-container, .quiz-start-page-container-mob {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}
.quiz-main-page-container, .quiz-main-page-container-mob {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 16px 0px 16px;
}
.quiz-flow-body, .quiz-flow-body-mob {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
.quiz-flow-title, .quiz-flow-title-mob {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 24px;
  line-height: 29px;
  color: #FFFFFF;
  text-align: center;
}
.quiz-flow-answers-container {
  display: flex;
  margin-top: 24px;
  flex-wrap: wrap;
  gap: 12px;
}

.quiz-flow-answer, .quiz-flow-answer-mob, .quiz-flow-answer-active, .quiz-flow-answer-mob-active {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  background: #303333;
  border-radius: 50px;
  height: 40px;
  font-size: 16px;
  line-height: 24px;
  color: #BAC4C4;
  border: double 2px transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quiz-flow-answer-active, .quiz-flow-answer-mob-active {
  color: white;
  background-image: linear-gradient(#303333, #303333), radial-gradient(circle at top left, #21FFB0 33.39%, #21E9EB 64.73%, #21E2FF 75.17%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.quiz-flow-question-next, .quiz-flow-question-next-mob {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 18px;
  line-height: 21px;
  height: 44px;
  background: #FFFFFF;
  border-radius: 40px;
  color: #000000;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: transparent;
}
.quiz-flow-question-next:hover, .quiz-flow-question-next-mob:hover {
  cursor: pointer;
  opacity: 0.7;
}
.quiz-flow-question-next:disabled, .quiz-flow-question-next-mob:disabled {
  background: #BAC4C4;
  border-color: transparent;
  color: #3B3F3F;
}
.quiz-flow-progress, .quiz-flow-progress-mob {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.quiz-flow-progress-bar-container, .quiz-flow-progress-bar-container-mob {
  display: flex;
  gap: 8px;
  width: 100%;
}
.quiz-flow-progress-bar, .quiz-flow-progress-bar-active {
  background: #262828;
  height: 4px;
  border-radius: 10px;
  flex: 1;
  width: 100%;
}
.quiz-flow-progress-bar-active {
  background: #FFFFFF;
}

.quiz-flow-progress-text, .quiz-flow-progress-text-active {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 17px;
  color: #BAC4C4;
}
.quiz-flow-back-container {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  align-items: center;
}
.quiz-flow-back-container:hover {
  cursor: pointer;
  opacity: 0.7;
}

.quiz-flow-imgs-container, .quiz-flow-imgs-container-mob {
  display: flex;
  align-self: center;
  padding-top: 16px;
  margin-bottom: auto;
}
.quiz-flow-img, .quiz-flow-img-mob {
  width: 100%;
}
.quiz-flow-finish-body, .quiz-flow-finish-body-mob {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.quiz-finish-page-container, .quiz-finish-page-container-mob {
  height: 100%;
}
.quiz-flow-finish-progress-percent {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-weight: 760;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  background: linear-gradient(85.81deg, #21FFB0 33.39%, #21E9EB 64.73%, #21E2FF 75.17%);
  display: flex;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.quiz-flow-finish-progress-title, .quiz-flow-finish-progress-title-mob {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-weight: 760;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}
.quiz-flow-finish-before-after-container {
  position: relative;
  height: 264px;
  display: flex;
  align-items: center;
}

.video-quiz {
  width: 100%;
  object-fit: cover;
}

.quiz-main-subtitle, .quiz-main-subtitle-mob {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 20px;
}
.quiz-main-before-after {
  display: grid;
  place-items: center;
  position: relative;
  margin-bottom: -2px;
  margin-top: 12px;
}

.quiz-main-before-after-container, .quiz-main-before-after-container-mob {
  display: grid;
  place-items: center;
  height: 360px;
  top: 6px;
  --position: 50%;
  position: relative;
  overflow: hidden;
}
.quiz-main-before-after-subcontainer, .quiz-main-before-after-subcontainer-mob {
  aspect-ratio: 1/1;
  max-width: 800px;
}
.quiz-main-before-after-subcontainer img, .quiz-main-before-after-subcontainer-mob img {
  display: block;
  max-width: 274px;
  height: 343px;
}
.quiz-iphone-img, .quiz-iphone-img-mob {
  position: absolute;
  z-index: 3;
  max-width: 302px;
  width: 302px;
  height: 355px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.quiz-main-line {
  min-height: 1px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5019607843);
}

.paywall-page-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
}

.paywall-offer-view-container, .paywall-offer-view-container-mob {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
}
.paywall-offer-background-container, .paywall-offer-background-container-mob {
  position: relative;
}
.paywall-offer-before-container, .paywall-offer-before-container-mob {
  background: url(../imgs/paywall-before-background.webp) no-repeat;
  background-size: contain;
  width: -webkit-fill-available;
  z-index: 1;
}
.paywall-offer-after-container, .paywall-offer-after-container-mob {
  background: url(../imgs/paywall-after-background.webp) no-repeat;
  background-size: contain;
  width: -webkit-fill-available;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 2;
}
.paywall-offer-bar, .paywall-offer-bar-mob, .paywall-offer-bar-active, .paywall-offer-bar-mob-active {
  display: flex;
  height: 0px;
  background: #F3E494;
  transition: all 0.5s;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  transform: rotateX(90deg);
}
.paywall-offer-bar-active, .paywall-offer-bar-mob-active {
  height: 40px;
  transform: rotateX(0deg);
}
.paywall-offer-text, .paywall-offer-text-mob {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-weight: 800;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}
.paywall-body, .paywall-body-mob {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 16px 16px 16px;
  background: linear-gradient(180deg, rgba(180, 20, 130, 0) 26%, rgb(0, 0, 0) 44%);
  z-index: 3;
  height: 100%;
  overflow-y: auto;
}
.paywall-description-container, .paywall-description-container-mob {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 25px;
}
.paywall-description-title, .paywall-description-title-mob {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.28px;
  color: #FFFFFF;
  text-align: center;
}
.paywall-description-list, .paywall-description-list-mob {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  gap: 13px;
  align-self: center;
}
.paywall-description, .paywall-description-mob {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
}
.paywall-description:before, .paywall-description-mob:before {
  content: "";
  display: inline-block;
  background: url(../imgs/paywall-description-star.png) no-repeat;
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  padding-right: 12px;
}
.paywall-description-features-container, .paywall-description-features-container-mob {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}
.paywall-description-feature, .paywall-description-feature-mob {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}
.paywall-description-feature-title, .paywall-description-feature-title-mob {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}
.paywall-description-feature-subtitle, .paywall-description-feature-subtitle-mob {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  color: #BAC4C4;
}
.paywall-description-feature-line {
  width: 1px;
  height: 40px;
  background-color: #3B3F3F;
}

.paywall-step-container, .paywall-step-container-mob {
  display: flex;
  width: 24px;
  justify-content: space-between;
  margin-top: 12px;
}
.paywall-step, .paywall-step-active {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #262828;
}
.paywall-step-active {
  background: #FFFFFF;
}

.paywall-products-container, .paywall-products-list-mob, .paywall-products-container-mob {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
}
.paywall-products-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.paywall-product, .paywall-product-mob, .paywall-product-active, .paywall-product-active-mob {
  height: 70px;
  width: 100%;
  display: flex;
  position: relative;
  -webkit-mask-composite: destination-out;
  align-items: center;
}
.paywall-product::before, .paywall-product-mob::before, .paywall-product-active::before, .paywall-product-active-mob::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 40px;
  border: 2px solid transparent;
  background: #3B3F3F border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}
.paywall-product-active::before, .paywall-product-active-mob::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 40px;
  border: 2px solid transparent;
  background: linear-gradient(85.81deg, #21FFB0 33.39%, #21E9EB 64.73%, #21E2FF 75.17%) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}
.product-circle, .product-circle-active {
  width: 28px;
  height: 28px;
  border: 2px solid #3B3F3F;
  border-radius: 30px;
  margin-left: 25px;
}
.product-circle-active {
  width: 32px;
  height: 32px;
  border: 0px solid #3B3F3F;
  background: url("../imgs/product-select.png") no-repeat;
  background-size: contain;
}

.paywall-product-price-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: 16px;
}

.paywall-product-price-title {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}

.paywall-product-price-subtitle {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}

.paywall-product-price-save, .paywall-product-price-save-active {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  width: 94px;
  height: 24px;
  position: absolute;
  background: #3B3F3F;
  border-radius: 137.5px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  z-index: 4;
  top: -12px;
  right: 9%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.paywall-product-price-save-active {
  background: #00F5EA;
  color: #000000;
}

.paywall-guarantee-container {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 17px;
  color: #BAC4C4;
  align-self: center;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.paywall-guarantee-container::before {
  content: "";
  background: url("../imgs/paywall-guarantee.png") no-repeat;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  padding-right: 8px;
}

.header-onboarding, .header-onboarding-mob {
  position: relative;
  width: 100%;
  display: flex;
}
.fltr-onboarding-logo-container, .fltr-onboarding-logo-container-mob {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.fltr-onboarding-logo-container-mob {
  left: 50%;
  transform: translate(-50%, 0%);
  top: 16px;
}

.fltr-onboarding-logo, .fltr-onboarding-logo-mob {
  width: 90px;
  height: 31px;
}
.fltr-onboarding-logo-mob {
  width: 90px;
  height: 31px;
}

.video-paywall {
  width: 100%;
  object-fit: cover;
  position: absolute;
}

.payment-page-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.payment-body, .payment-body-mob {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 24px;
  gap: 12px;
}
.payment-solidgate-container, .payment-solidgate-container-mob {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.payment-order-summary-container, .payment-order-summary-container-mob {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 74px;
  margin-left: 16px;
  margin-right: 16px;
}
.payment-order-summary-title, .payment-order-summary-title-mob {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 24px;
  line-height: 24px;
  color: #FFFFFF;
}
.payment-order-summary-line, .payment-order-summary-line-mob {
  border: 1px solid #3B3F3F;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}
.payment-order-summary-total, .payment-order-summary-total-mob {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.payment-order-summary-description-container, .payment-order-summary-description-container-mob {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  gap: 12px;
}
.payment-order-summary-description, .payment-order-summary-description-mob {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 16px;
  color: #BAC4C4;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.apple-pay-container {
  width: 100%;
}
.apple-pay-container .apple-pay-button {
  border-radius: 20px !important;
  height: 48px;
}

.payment-result-container, .payment-result-container-mob {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 87px 16px 16px 16px;
  height: 100%;
}
.payment-result-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
}

.payment-result-image, .payment-result-image-failed, .payment-result-image-success {
  width: 110px;
  height: 110px;
}

.payment-result-image-success {
  background: url(../imgs/payment-result-success.png) no-repeat;
  background-size: cover;
}

.payment-result-image-failed {
  background: url(../imgs/payment-result-failed.png) no-repeat;
  background-size: cover;
}

.payment-result-title, .payment-result-title-mob {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 32px;
  line-height: 38px;
  color: #FFFFFF;
  margin-top: 33px;
}
.payment-result-order-id, .payment-result-order-id-mob {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin-top: 8px;
  text-align: center;
}
.payment-result-get-receipt, .payment-result-get-receipt-mob {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  color: #FFFFFF;
  margin-top: 8px;
}
.payment-result-get-receipt:hover, .payment-result-get-receipt-mob:hover {
  cursor: pointer;
  opacity: 0.8;
}
.payment-result-description, .payment-result-description-mob {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #BAC4C4;
  align-self: center;
  padding-top: 32px;
}
.payment-result-sub-body-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  width: 100%;
}

.terms-policy-footer {
  display: flex;
  gap: 6px;
  align-items: center;
  margin-top: auto;
}
.terms-policy-footer .point {
  width: 2px;
  height: 2px;
  background-color: #BAC4C4;
}
.terms-policy-footer a {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #BAC4C4;
}

.paypal-button {
  height: 48px;
  background-color: #FFC43A;
  border-radius: 50px;
  background-image: url(../imgs/paypall-logo.png);
  background-size: 91px 22px;
  background-repeat: no-repeat;
  background-position: center;
}

.google-pay-container {
  width: 100%;
  height: 48px;
}
.google-pay-container .gpay-button {
  border-radius: 50px;
}

.bank-card-button {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  height: 48px;
  width: 100%;
  background-color: transparent;
  border-radius: 50px;
  border: 1px solid #303333;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}

.payments-title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 17px;
  color: #BAC4C4;
}

.or {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #BAC4C4;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
}
.or::after {
  content: "";
  margin-left: 35px;
  width: 100%;
  height: 1px;
  background-color: #3B3F3F;
}
.or::before {
  content: "";
  margin-right: 35px;
  width: 100%;
  height: 1px;
  background-color: #3B3F3F;
}

.payment-agree {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: #BAC4C4;
  font-size: 12px;
  padding: 24px 16px 16px;
}
.payment-agree a {
  color: #BAC4C4;
  text-decoration: underline;
}

