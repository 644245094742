.payment-page-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.payment-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 24px;
  gap: 12px;
  &-mob {
    @extend .payment-body;
  }
}

.payment-solidgate-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-mob {
    @extend .payment-solidgate-container;
  }
}

.payment-order-summary-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 74px;
  margin-left: 16px;
  margin-right: 16px;
  &-mob {
    @extend .payment-order-summary-container;
  }
}

.payment-order-summary-title {
  @include sf-bold;
  font-size: 24px;
  line-height: 24px;
  color: #FFFFFF;
  &-mob {
    @extend .payment-order-summary-title;
  }
}

.payment-order-summary-line {
  border: 1px solid #3B3F3F;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  &-mob {
    @extend .payment-order-summary-line;
  }
}

.payment-order-summary-total {
  @include sf-semibold;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  &-mob {
    @extend .payment-order-summary-total;
  }
}

.payment-order-summary-description-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  gap: 12px;
  &-mob {
    @extend .payment-order-summary-description-container;
  }
}

.payment-order-summary-description {
  @include sf-bold;
  font-size: 16px;
  color: #BAC4C4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-mob {
    @extend .payment-order-summary-description;
  }
}

.apple-pay-container {
  width: 100%;
  .apple-pay-button {
    border-radius: 20px!important;
    height: 48px;
  }
}

.payment-result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 87px 16px 16px 16px ;
  height: 100%;
  &-mob {
    @extend .payment-result-container;
  }
}

.payment-result-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
}

.payment-result-image {
  width: 110px;
  height: 110px;
}

.payment-result-image-success {
  @extend .payment-result-image;
  background: url(../imgs/payment-result-success.png) no-repeat;
  background-size: cover;
}

.payment-result-image-failed {
  @extend .payment-result-image;
  background: url(../imgs/payment-result-failed.png) no-repeat;
  background-size: cover;
}

.payment-result-title {
  @include sf-bold;
  font-size: 32px;
  line-height: 38px;
  color: #FFFFFF;
  margin-top: 33px;
  &-mob {
    @extend .payment-result-title;
  }
}

.payment-result-order-id {
  @include sf-regular;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin-top: 8px;
  text-align: center;
  &-mob {
    @extend .payment-result-order-id;
  }
}

.payment-result-get-receipt {
  @include sf-regular;
  @include transition;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  color: #FFFFFF;
  margin-top: 8px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &-mob {
    @extend .payment-result-get-receipt;
  }
}

.payment-result-description {
  @include sf-regular;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #BAC4C4;
  align-self: center;
  padding-top: 32px;
  &-mob {
    @extend .payment-result-description;
  }
}

.payment-result-sub-body-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  width: 100%;
}

.terms-policy-footer {
  display: flex;
  gap: 6px;
  align-items: center;
  margin-top: auto;
  .point {
    width: 2px;
    height: 2px;
    background-color: #BAC4C4;
  }
  a {
    @include sf-regular;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #BAC4C4;
  }
}

.paypal-button {
  height: 48px;
  background-color: #FFC43A;
  border-radius: 50px;
  background-image: url(../imgs/paypall-logo.png);
  background-size: 91px 22px;
  background-repeat: no-repeat;
  background-position: center;
}

.google-pay-container {
  width: 100%;
  height: 48px;
  .gpay-button{
    border-radius: 50px;
  }
}

.bank-card-button {
  @include sf-medium;
  height: 48px;
  width: 100%;
  background-color: transparent;
  border-radius: 50px;
  border: 1px solid #303333;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}

.payments-title {
  @include sf-regular;
  font-size: 14px;
  line-height: 17px;
  color: #BAC4C4
}

.or {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #BAC4C4;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  &::after {
    content: '';
    margin-left: 35px;
    width: 100%;
    height: 1px;
    background-color: #3B3F3F;
  }
  &::before {
    content: '';
    margin-right: 35px;
    width: 100%;
    height: 1px;
    background-color: #3B3F3F;
  }
}

.payment-agree {
  @include sf-regular;
  color: #BAC4C4;
  font-size: 12px;
  padding: 24px 16px 16px;
  a {
    color: #BAC4C4;
    text-decoration: underline;
  }
}
