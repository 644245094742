.quiz-page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.quiz-main-background-container {
  @extend .background-container;
  background: url(../imgs/background_main-quiz.webp) no-repeat;
  background-size: cover!important;
  &-mob{
    @extend .quiz-main-background-container;
  }
}

.quiz-start-background-container {
  @extend .background-container;
  background: url(../imgs/background_start-quiz.webp) no-repeat;
  &-mob{
    @extend .quiz-start-background-container;
  }
}

.quiz-finish-background-container {
  @extend .background-container;
  background: url(../imgs/background_finish-quiz.webp) no-repeat;
  &-mob{
    @extend .quiz-finish-background-container;
  }
}

.quiz-flow-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  margin: 64px 16px 0px 16px;
  &-mob {
    @extend .quiz-flow-container;
  }
}

.quiz-start-page-container {
  @extend .quiz-flow-container;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  &-mob {
    @extend .quiz-start-page-container;
  }
}

.quiz-main-page-container {
  @extend .quiz-flow-container;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 16px 0px 16px;
  &-mob {
    @extend .quiz-main-page-container;
  }
}

.quiz-flow-body {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  &-mob {
    @extend .quiz-flow-body;
  }
}

.quiz-flow-title {
  @include sf-bold;
  font-size: 24px;
  line-height: 29px;
  color: #FFFFFF;
  text-align: center;
  &-mob {
    @extend .quiz-flow-title;
  }
}

.quiz-flow-answers-container {
  display: flex;
  margin-top: 24px;
  flex-wrap: wrap;
  gap: 12px
}

.quiz-flow-answer {
  @include sf-regular;
  @include transition;
  background: #303333;
  border-radius: 50px;
  height: 40px;
  font-size: 16px;
  line-height: 24px;
  color: #BAC4C4;
  border: double 2px transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  &-active {
    @extend .quiz-flow-answer;
    color: white;
    background-image: linear-gradient(#303333, #303333), radial-gradient(circle at top left, #21FFB0 33.39%, #21E9EB 64.73%, #21E2FF 75.17%);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  &-mob {
    @extend .quiz-flow-answer;
    &-active {
      @extend .quiz-flow-answer-active;
    }
  }
}

.quiz-flow-question-next {
  @include sf-bold;
  font-size: 18px;
  line-height: 21px;
  height: 44px;
  background: #FFFFFF;
  border-radius: 40px;
  color: #000000;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: transparent;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  &:disabled {
    background: #BAC4C4;
    border-color: transparent;
    color: #3B3F3F;
  }
  &-mob {
    @extend .quiz-flow-question-next;
  }
}

.quiz-flow-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  &-mob {
    @extend .quiz-flow-progress;
  }
}

.quiz-flow-progress-bar-container {
  display: flex;
  gap: 8px;
  width: 100%;
  &-mob {
    @extend .quiz-flow-progress-bar-container;
  }
}

.quiz-flow-progress-bar {
  background: #262828;
  height: 4px;
  border-radius: 10px;
  flex: 1;
  width: 100%;
  &-active {
    @extend .quiz-flow-progress-bar;
    background: #FFFFFF;
  }
}

.quiz-flow-progress-text {
  @include sf-regular;
  font-size: 14px;
  line-height: 17px;
  color: #BAC4C4;
  &-active {
    @extend .quiz-flow-progress-text;
  }
}

.quiz-flow-back-container {
  @include transition;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.quiz-flow-imgs-container {
  display: flex;
  align-self: center;
  padding-top: 16px;
  margin-bottom: auto;
  &-mob{
    @extend .quiz-flow-imgs-container;
  }
}

.quiz-flow-img {
  width: 100%;
  &-mob {
    @extend .quiz-flow-img;
  }
}

.quiz-flow-finish-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  &-mob {
    @extend .quiz-flow-finish-body;
  }
}

.quiz-finish-page-container {
  height: 100%;
  &-mob {
    @extend .quiz-finish-page-container;
  }
}

.quiz-flow-finish-progress-percent {
  @include sf-bold;
  font-weight: 760;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  background: linear-gradient(85.81deg, #21FFB0 33.39%, #21E9EB 64.73%, #21E2FF 75.17%);
  display: flex;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.quiz-flow-finish-progress-title {
  @include sf-bold;
  font-weight: 760;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  &-mob {
    @extend .quiz-flow-finish-progress-title;
  }
}

.quiz-flow-finish-before-after-container {
  position: relative;
  height: 264px;
  display: flex;
  align-items: center;
}

.video-quiz {
  width: 100%;
  object-fit: cover;
}

.quiz-main-subtitle {
  @include sf-semibold;
  @extend .fltr-general-text-gradient;
  font-size: 20px;
  &-mob {
    @extend .quiz-main-subtitle;
  }
}

.quiz-main-before-after {
  display: grid;
  place-items: center;
  position: relative;
  margin-bottom: -2px;
  margin-top: 12px;
}

.quiz-main-before-after-container {
  display: grid;
  place-items: center;
  height: 360px;
  top: 6px;
  --position: 50%;
  position: relative;
  overflow: hidden;
  &-mob {
    @extend .quiz-main-before-after-container;
  }
}

.quiz-main-before-after-subcontainer {
  aspect-ratio: 1/1;
  max-width: 800px;
  img {
    display: block;
    max-width: 274px;
    height: 343px;
  }
  &-mob {
    @extend .quiz-main-before-after-subcontainer;
  }
}

.quiz-iphone-img {
  position: absolute;
  z-index: 3;
  max-width: 302px;
  width: 302px;
  height: 355px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &-mob {
    @extend .quiz-iphone-img;
  }
}

.quiz-main-line {
  min-height: 1px;
  width: 100%;
  background-color: #FFFFFF80;
}
