@mixin sf-bold {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

@mixin sf-semibold {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

@mixin sf-regular {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

@mixin sf-medium {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

@mixin noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

@mixin cuttext {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin transition{
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
}

@mixin fltr-btn() {
  @include noselect;
  @include transition;
  @include sf-semibold;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}

a{
  text-decoration: none;
}

html {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

body {
  @include sf-regular;
  margin: 0;
  background-color: black;
  overflow-y: visible;
  overflow-x: hidden;
  position: relative;
}

@media (min-width: 480px) {
  body {
    @include sf-regular;
    background-color: black;
    max-width: 430px;
    margin: auto;
    overflow-x: hidden;
  }
  .cookies-container {
    max-width: 430px;
  }
}

.root {
  height: 100%;
}

.app-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  overflow-x: hidden;
  position: relative;
}

.body-container {
  position: relative;
}

.fltr-general-text-gradient {
  background: linear-gradient(85.81deg, #21FFB0 33.39%, #21E9EB 64.73%, #21E2FF 75.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.general-input {
  @include sf-regular;
  height: 48px;
  border: 1px solid #505050;
  border-radius: 50px;
  background-color: transparent;
  color: white;
  font-size: 16px;
  line-height: 24px;
  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;
  &:focus {
    border-color: #FFFFFF;
    outline:none
  }
  &::placeholder {
    @include sf-regular;
    font-size: 16px;
    line-height: 24px;
    color: #303333;
  }
}

.general-btn {
  @include fltr-btn;
  @include sf-bold;
  @include transition;
  font-size: 18px;
  line-height: 21px;
  color: black;
  max-width: 400px;
  width: 100%;
  min-height: 56px;
  transition: background-image 0.5s linear;
  background-image: linear-gradient(85.81deg, #21FFB0 33.39%, #21E9EB 64.73%, #21E2FF 75.17%);
  position: relative;
  &:hover {
    cursor: pointer;
    opacity: 0.7
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
  }
  &-mob {
    @extend .general-btn;
    height: 56px;
    width: 100%;
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
  @keyframes changePosition {
    0% {
      right: 130px;
    }
    50% {
      right: 100px;
    }
    100% {
      right: 130px;
    }
  }
}

.empty-gradient-btn {
  @extend .general-btn;
  @extend .fltr-general-text-gradient;
  font-size: 17px;
  font-style: normal;
  line-height: 24px;
  text-transform: capitalize;
  min-height: 20px;
  .arrow-btn {
    background: url(../imgs/arrow-right.svg) no-repeat;
    background-size: cover;
    position: absolute;
    right: 60px;
    width: 20px;
    height: 17px;
    animation-duration: 1.5s;
    animation-name: changePosition;
    animation-iteration-count: infinite;
    animation-direction: normal;
  }
}

.second-btn {
  @include fltr-btn;
  @include sf-bold;
  @include transition;
  font-size: 18px;
  line-height: 21px;
  color: black;
  max-width: 400px;
  height: 56px;
  transition: background 0.5s linear;
  background: #FFFFFF;
  &:hover {
    cursor: pointer;
    opacity: 0.7
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
  }
  &-mob {
    @extend .second-btn;
    height: 56px;
    width: 100%;
  }
}

.title-text {
  @include sf-bold;
  color: white;
  font-size: 32px;
  line-height: 38px;
  &-mob {
    @extend .title-text;
    font-size: 32px;
    line-height: 38px;
  }
}

.title-text {
  @include sf-bold;
  color: white;
  font-size: 30px;
  line-height: normal;
  &-mob {
    @extend .title-text;
  }
}

.title {
  @include sf-bold;
  color: white;
  font-size: 30px;
  line-height: normal;
  text-align: center;
  &-mob {
    @extend .title;
  }
}

.second-text {
  @include sf-regular;
  color: #BAC4C4;
  &-mob {
    @extend .second-text;
    font-size: 16px;
    line-height: 24px;
  }
}

.spinner-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fltr-logo {
  width: 85px;
  height: 28px;
  &-mob {
    @extend .fltr-logo;
  }
}

.try-now {
  @include transition;
  width: 90px;
  height: 31px;
  &-mob {
    @extend .try-now;
    width: 90px;
    height: 28px;
  }
}

.background-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: contain!important;
}

.arrow {
  border: solid #BAC4C4;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  width: 10px;
  height: 10px
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.back {
  @include sf-regular;
  @include noselect;
  position: absolute;
  font-size: 16px;
  line-height: 24px;
  left: 25px;
  top: 25px;
  color: #BAC4C4;
  &::before {
    margin-right: 8px;
    content: '';
    @extend .arrow;
    @extend .left;
  }
}

.progress-bar-container {
  position: relative;
  height: 16px;
}

.progress-bar-back {
  position: absolute;
  width: 100%;
  height: 16px;
  background: linear-gradient(270deg, rgba(73, 244, 255, 0.175) -0.42%, rgba(255, 255, 255, 0) 100%);
  border-radius: 20px;
  z-index: 1;
}

.progress-bar-front {
  position: absolute;
  height: 16px;
  background: linear-gradient(85.81deg, #21FFB0 33.39%, #21E9EB 64.73%, #21E2FF 75.17%);
  border-radius: 20px;
  z-index: 2;
  width: 0%;
  transition: width 10s linear;
  &:after {
    content:"";
    position: absolute;
    top: -2px;
    right: -10px;
    background: #1E2424;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
  }
}

.store-image {
  width: 165px;
  height: 50px;
}

.apple-store-image {
  @extend .store-image;
  background: url(../imgs/apple-store.png) no-repeat;
  background-size: cover;
}

.google-store-image {
  @extend .store-image;
  background: url(../imgs/google-store.png) no-repeat;
  background-size: cover;
}

.cookies-container {
  @include sf-regular;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  position: fixed;
  top: calc(100vh - 36px);
  padding: 4px 16px;
  background-color: #000000CC;
  color: #BAC4C4;
  align-items: center;
  gap: 8px;
  z-index: 100;
  a {
    padding-left: 2px;
    color: #BAC4C4;
    text-decoration: underline;
  }
  .allow-btn {
    @include sf-bold;
    color: black;
    font-size: 12px;
    border-radius: 40px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    padding: 0px 24px;
    &:hover {
      cursor: pointer;
    }
  }
  &-mob {
    @extend .cookies-container;
  }
}

.header-label {
  @include sf-regular;
  color: white;
  font-size: 16px;
  line-height: 24px;
  &-mob {
    @extend .header-label;
  }
}

.img-comp-slider {
  position: absolute;
  z-index: 6;
  cursor: ew-resize;
  /*set the appearance of the slider:*/
  width: 40px;
  height: 40px;
  background-color: #2196F3;
  opacity: 0.7;
  border-radius: 50%;
}

.privacy-terms-container {
  @include sf-regular;
  font-size: 18px;
  padding: 16px;
  text-align: center;
  overflow-y: auto;
  color: white;
  &-mob {
    @extend .privacy-terms-container;
  }
}

.burger-button {
  transition-duration: 0.5s;
  position: relative;
  width: 24px;
  height: 24px;
  .icon {
    transition-duration: 0.5s;
    position: absolute;
    height: 0px;
    width: 15px;
    top: 11px;
    background-color: white;
    &:before{
      transition-duration: 0.5s;
      position: absolute;
      width: 21px;
      height: 2px;
      background-color: white;
      content: "";
      top: -4px;
      border-radius: 5px;
    }

    &:after{
      transition-duration: 0.5s;
      position: absolute;
      width: 21px;
      height: 2px;
      background-color: white;
      content: "";
      top: 4px;
      border-radius: 5px;
    }
  }

  &.open {
    .icon {
      transition: 0.5s;

      &:before{
        transform: rotateZ(45deg) scaleX(1.25) translate(2px, 2px);
        width: 17px;
      }

      &:after{
        transform: rotateZ(-45deg) scaleX(1.25) translate(3px, -3px);
        width: 17px;
      }
    }
  }
  &:hover {
    cursor: pointer;
  }
}

.header {
  @include transition;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  z-index: 1;
  overflow: hidden;
  -webkit-transition: all 0.5s ease-out, background 1s ease-out;
  transition: all 0.5s ease-out, background 1s ease-out;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  z-index: 100;
  &-mob {
    @extend .header;
  }
}

.menu {
  @include transition;
  height: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: black;
  gap: 131px;
  justify-content: center;
  &-opened {
    @extend .menu;
    height: 100svh;
  }
}

.menu-item {
  a {
    @include sf-regular;
    color:  #BAC4C4;
    font-size: 20px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
  }
}




