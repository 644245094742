.paywall-page-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
}

.paywall-offer-view-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  &-mob {
    @extend .paywall-offer-view-container;
  }
}

.paywall-offer-background-container {
  position: relative;
  &-mob {
    @extend .paywall-offer-background-container;
  }
}

.paywall-offer-before-container {
  @extend .background-container;
  background: url(../imgs/paywall-before-background.webp) no-repeat;
  background-size: contain;
  width: -webkit-fill-available;
  z-index: 1;
  &-mob {
    @extend .paywall-offer-before-container;
  }
}

.paywall-offer-after-container {
  @extend .background-container;
  background: url(../imgs/paywall-after-background.webp) no-repeat;
  background-size: contain;
  width: -webkit-fill-available;
  opacity: 0;
  transition: opacity .5s;
  z-index: 2;
  &-mob {
    @extend .paywall-offer-after-container;
  }
}

.paywall-offer-bar {
  display: flex;
  height: 0px;
  background: #F3E494;
  transition: all .5s;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  transform: rotateX(90deg);
  &-active {
    @extend .paywall-offer-bar;
    height: 40px;
    transform: rotateX(0deg);
  }
  &-mob {
    @extend .paywall-offer-bar;
    &-active {
      @extend .paywall-offer-bar-active;
    }
  }
}

.paywall-offer-text {
  @include sf-bold;
  font-weight: 800;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  &-mob {
    @extend .paywall-offer-text;
  }
}

.paywall-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 16px 16px 16px;
  background: linear-gradient(180deg, rgba(180,20,130,0) 26%, rgba(0,0,0,1) 44%);
  z-index: 3;
  height: 100%;
  overflow-y: auto;
  &-mob {
    @extend .paywall-body;
  }
}

.paywall-description-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 25px;
  &-mob {
    @extend .paywall-description-container;
  }
}

.paywall-description-title {
  @include sf-bold;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.28px;
  color: #FFFFFF;
  text-align: center;
  &-mob {
    @extend .paywall-description-title;
  }
}

.paywall-description-list {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  gap: 13px;
  align-self: center;
  &-mob {
    @extend .paywall-description-list;
  }
}

.paywall-description {
  @include sf-semibold;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  &:before {
    content: '';
    display: inline-block;
    background: url(../imgs/paywall-description-star.png) no-repeat;
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    padding-right: 12px;
  }

  &-mob {
    @extend .paywall-description;
  }
}

.paywall-description-features-container {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  &-mob {
    @extend .paywall-description-features-container;
  }
}

.paywall-description-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  &-mob {
    @extend .paywall-description-feature;
  }
}

.paywall-description-feature-title {
  @include sf-semibold;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  &-mob {
    @extend .paywall-description-feature-title;
  }
}

.paywall-description-feature-subtitle {
  @include sf-regular;
  font-size: 14px;
  color: #BAC4C4;
 &-mob {
   @extend .paywall-description-feature-subtitle;
 }
}

.paywall-description-feature-line {
  width: 1px;
  height: 40px;
  background-color: #3B3F3F;
}

.paywall-step-container {
  display: flex;
  width: 24px;
  justify-content: space-between;
  margin-top: 12px;
  &-mob {
    @extend .paywall-step-container;
  }
}

.paywall-step {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #262828;
  &-active {
    @extend .paywall-step;
    background: #FFFFFF;
  }
}

.paywall-products-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
  &-mob {
    @extend .paywall-products-container;
  }
}

.paywall-products-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  &-mob {
    @extend .paywall-products-container;
  }
}

.paywall-product {
  height: 70px;
  width: 100%;
  display: flex;
  position: relative;
  -webkit-mask-composite: destination-out;
  align-items: center;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 40px;
    border: 2px solid transparent;
    background: #3B3F3F border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
  }
  &-active {
    @extend .paywall-product;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 40px;
      border: 2px solid transparent;
      background: linear-gradient(85.81deg, #21FFB0 33.39%, #21E9EB 64.73%, #21E2FF 75.17%) border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      mask-composite: exclude;
    }
    &-mob {
      @extend .paywall-product-active;
    }
  }
  &-mob {
    @extend .paywall-product;
  }
}

.product-circle {
  width: 28px;
  height: 28px;
  border: 2px solid #3B3F3F;
  border-radius: 30px;
  margin-left: 25px;
  &-active {
    @extend .product-circle;
    width: 32px;
    height: 32px;
    border: 0px solid #3B3F3F;
    background: url("../imgs/product-select.png") no-repeat;
    background-size: contain;
  }
}

.paywall-product-price-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: 16px;
}

.paywall-product-price-title {
  @include sf-bold;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}

.paywall-product-price-subtitle {
  @include sf-regular;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}

.paywall-product-price-save {
  @include sf-bold;
  width: 94px;
  height: 24px;
  position: absolute;
  background: #3B3F3F;
  border-radius: 137.5px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  z-index: 4;
  top: -12px;
  right: 9%;
  display: flex;
  justify-content: center;
  align-items: center;
  &-active {
    @extend .paywall-product-price-save;
    background: #00F5EA;
    color: #000000;
  }
}

.paywall-guarantee-container {
  @include sf-regular;
  font-size: 14px;
  line-height: 17px;
  color: #BAC4C4;
  align-self: center;
  display: flex;
  align-items: center;
  margin-top: 20px;
  &::before {
    content: "";
    background: url("../imgs/paywall-guarantee.png") no-repeat;
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
    padding-right: 8px;
  }
}

.header-onboarding {
  position: relative;
  width: 100%;
  display: flex;
  &-mob {
    @extend .header-onboarding;
  }
}

.fltr-onboarding-logo-container {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  &-mob {
    @extend .fltr-onboarding-logo-container;
    left: 50%;
    transform: translate(-50%, 0%);
    top: 16px;
  }
}

.fltr-onboarding-logo {
  width: 90px;
  height: 31px;
  &-mob {
    @extend .fltr-onboarding-logo;
    width: 90px;
    height: 31px;
  }
}

.video-paywall {
  width: 100%;
  object-fit: cover;
  position: absolute;
}
